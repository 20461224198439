import * as React from "react";
import { graphql } from "gatsby";
import { Grid, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    background: "#09333F",
    backgroundSize: "100% 100%",
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    textAlign: "center",
  },
  typo: {
    fontWeight: 600,
    color: "#fff",
  },
});

// markup
const NotFoundPage = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.text}>
        <Grid item xs={12}>
          <Typography variant="h2" className={classes.typo}>
            404
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2" className={classes.typo}>
            Oh da ist leider etwas schiefgelaufen
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
